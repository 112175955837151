import React from "react"

import styles from "./content.module.scss"
import { AccountGuard } from "../../../../guards/account.guard"

const ContentPage7 = () => {
  return (
    <AccountGuard>
      <div className={`${styles.header}`}>
        <h2>Technical Official Introductory</h2>
      </div>

      <div className={`${styles.wrapper}`}>
        <div className={`${styles.sectionHeader}`}>
          <h3>Section 07</h3>
          <h1>Following The Event</h1>
        </div>
      </div>

      <div className={`${styles.contentWrapper}`}>
        <ul className={`${styles.content}`}>
          <li>
            <p>
              <b>Debrief</b>
            </p>

            <p>
              Following each event, Technical Officials will be asked to provide
              their insight on the event to Head Technical Officials. This is
              the opportunity to share feedback with leadership in order to
              enhance and improve your experience and performance. These
              sessions may be conducted in person or through a survey.
            </p>

            <p>
              <b>Performance Reviews</b>
            </p>

            <p>
              Technical Officials will receive performance reviews following
              each international event. These ratings as well as other factors
              will be used in consideration of promotion opportunities and
              future assignment at iF3 events.
            </p>

            <p>
              Individuals are highly encouraged to assess their own performance
              in order to ensure continued performance improvement. As
              opportunities arise to promote individuals to higher roles, this
              level of commitment is expected.
            </p>
          </li>
        </ul>

        <div className={`${styles.buttons}`}>
          <div className={`${styles.previous}`}>
            <a href="/courses/toi/content/6">
              <i
                className={`fas fa-arrow-alt-circle-left uk-margin-small-right`}
              />
              Previous
            </a>
          </div>
          <div className={`${styles.next}`}>
            <a href="/courses/toi/content/8">
              Next
              <i
                className={`fas fa-arrow-alt-circle-right uk-margin-small-left`}
              />
            </a>
          </div>
        </div>
      </div>
    </AccountGuard>
  )
}

export default ContentPage7
